import $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

import improvedecision from "../src/improvedecision";
import { rbgRateGoodV100, rbgRateBadV100 } from "kaleido-event-tracker";

export default class extends Controller {
  static targets = ["inputBar", "shareBar", "thanksBar", "contributeUnsupportedBtn", "contributeUnsupportedThanksBar"];

  connect() {
    this.url = this.data.get("url");
    this.id = this.data.get("id");
  }

  submit(data) {
    $.ajax({
      url: this.url,
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      method: "PATCH",
      data: { image: data },
    });
  }

  rateGood(e) {
    e.preventDefault();
    $(this.inputBarTarget).slideUp({
      complete: function () {
        $(this).addClass("d-none").removeClass("d-flex");
      },
    });

    $(this.thanksBarTarget).slideDown();
    $(this.shareBarTarget).slideDown();

    this.submit({ rating: 1 });
    window.track("Images", "rate_good", "Rate good");
    rbgRateGoodV100({ image_id: this.id });
  }

  rateBad(e) {
    e.preventDefault();
    $(this.inputBarTarget).slideUp({
      complete: function () {
        $(this).addClass("d-none").removeClass("d-flex");
      },
    });
    $(this.thanksBarTarget).slideDown();

    var data = { rating: -1 };

    var decision = improvedecision.get();
    if (decision === undefined) {
      this.showImprovePopup();
      window.improveDialogCallback = (decision) => {
        if (decision == "accept") {
          this.submit({ contribute: true });
        }
      };
    } else if (decision == "accept") {
      data.contribute = true;
    } else if (decision == "reject") {
      // nothing to do
    }
    this.submit(data);

    window.track("Images", "rate_bad", "Rate bad");
    rbgRateBadV100({ image_id: this.id });
  }

  contributeUnsupported(e) {
    e.preventDefault();

    var decision = improvedecision.get();
    if (decision === undefined) {
      this.showImprovePopup();
      window.improveDialogCallback = (decision) => {
        if (decision == "accept") {
          this.submit({ contribute: true });
          $(this.contributeUnsupportedBtnTarget).slideUp();
          $(this.contributeUnsupportedThanksBarTarget).slideDown();
        }
      };
    } else if (decision == "accept") {
      this.submit({ contribute: true });
      $(this.contributeUnsupportedBtnTarget).slideUp();
      $(this.contributeUnsupportedThanksBarTarget).slideDown();
    }
  }

  showImprovePopup(e) {
    if (e) e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#improvedialog-popup",
      },
      type: "inline",
      closeOnBgClick: true,
    });
  }
}
