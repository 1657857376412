import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["image"]

  over() {
    this.imageTarget.style.backgroundImage = `url("${this.imageTarget.dataset.hoverImage}`
  }

  out() {
    this.imageTarget.style.backgroundImage = `url("${this.imageTarget.dataset.thumbnailImage}`
  }
}
