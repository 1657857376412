class Configs {
  static get(name: string): string | number | boolean {
    const configs = document.querySelector('meta[name="configs"]');

    try {
      const parsedConfigs = JSON.parse(configs?.getAttribute("content") || "{}");
      const value = parsedConfigs.hasOwnProperty(name);

      if (!value) return;

      return parsedConfigs?.[name];
    } catch (_error) {
      return;
    }
  }
}

export default Configs;
