function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      resolve(reader.result);
    }
  });
}

export default blobToBase64;
