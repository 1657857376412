import $ from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.initializeCurrentTab();
    $(this.element).find(".nav-link").on('shown.bs.tab', (e) => {
      this.initializeCurrentTab();
    });
  }

  initializeCurrentTab() {
    this.initializeTab($(this.element).find(".tab-pane.active"));
  }

  initializeTab(el) {
    if(el.data("initialized")) return;
    el.data("initialized", true);
  }
}
