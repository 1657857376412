import $ from "jquery";

var injected = false;
var loaded = false;
var callbacks = [];

function loadHcaptcha() {
  return new Promise((resolve, reject) => {
    if (loaded) return resolve();
    callbacks.push(resolve);
    if (injected) return;
    injected = true;

    window.hcaptchaLoaded = () => {
      loaded = true;

      for (var i = 0; i < callbacks.length; i++) {
        callbacks[i]();
      }
    };

    var script = document.createElement("script");
    script.setAttribute("src", "https://hcaptcha.com/1/api.js?onload=hcaptchaLoaded&render=explicit&recaptchacompat=off");
    script.async = true;
    document.head.appendChild(script);
  });
}

function hcaptcha_invisible(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha()
      .then(() => {
        var node = document.createElement("div");
        document.body.appendChild(node);
        try {
          var widgetID = hcaptcha.render(node, {
            sitekey: config.sitekey,
            size: "invisible",
            callback: (token) => {
              resolve(token);
              document.body.removeChild(node);
            },
            "error-callback": (error) => {
              reject({ message: `Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
            },
            "close-callback": () => {
              reject({ event: "close", message: `Please verify you are not a bot by completing the puzzle.` });
            },
          });
          hcaptcha.execute(widgetID);
        } catch (error) {
          reject({ message: `Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
        }
      })
      .catch((error) => {
        reject({ message: `Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
      });
  });
}

function hcaptcha_invisible_submit(form, config) {
  return new Promise((resolve, reject) => {
    if ($(form).data("hcaptcha-active")) return;
    $(form).data("hcaptcha-active", true);

    hcaptcha_invisible(config)
      .then((captcha) => {
        var field = $(form).find("input[name='hcaptcha_response']");
        if (field.length == 0) {
          field = $("<input type='hidden' name='hcaptcha_response' />");
          field.appendTo(form);
        }
        field.val(captcha);
        form.submit();
        $(form).data("hcaptcha-active", false);
        resolve();
      })
      .catch((error) => {
        if (error.event != "close") alert(error.message);
        $(form).data("hcaptcha-active", false);
        reject();
      });
  });
}

function hcaptcha_checkbox(config) {
  return new Promise((resolve, reject) => {
    loadHcaptcha()
      .then(() => {
        try {
          hcaptcha.render(config.element, {
            sitekey: config.sitekey,
            callback: (token) => {
              resolve(token);
            },
            "error-callback": (error) => {
              reject({ message: `Failed to run no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
            },
          });
        } catch (error) {
          reject({ message: `Failed to render no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
        }
      })
      .catch((error) => {
        reject({ message: `Failed to load no-bot verification (hCaptcha). Is there an issue with your internet connection? (${error})` });
      });
  });
}

$(document).on("submit", ".hcaptcha-form", function (e) {
  e.preventDefault();
  var submit = $(this).find(".hcaptcha-submit");
  submit.attr("disabled", true);
  hcaptcha_invisible_submit(this, { sitekey: $(this).data("hcaptcha-sitekey") }).finally(() => {
    submit.attr("disabled", false);
  });
});

export { hcaptcha_invisible, hcaptcha_invisible_submit, hcaptcha_checkbox };
