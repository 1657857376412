import $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  submit() {
    this.submitted()
  }

  submitted(e) {
    if(e) e.preventDefault();
    var form = $(this.formTarget);
    $.ajax({
      type: "PATCH",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      data: form.serialize(),
      url: form.attr("action"),
    })
  }

}
