import $ from "jquery";
import { Controller } from "stimulus";

import Vue from "vue";
import FullImage from "../components/full_image.vue";

import { createDownload } from "../src/download_utils.js";

import tippy from "tippy.js";
import Configs from "@/modules/configs";

export default class extends Controller {
  static targets = [
    "button",
    // the following are only needed for templates:
    "preview",
    "fullResolution",
  ];

  connect() {
    var isTemplate = this.data.get("template") == "true";
    if (isTemplate) {
      // fetch the preview image from window object
      var preview = window.templateBase64;
      $(this.previewTarget).attr("src", preview);
      var fullResolutionText = `Up to ${Configs.get("max_output_resolution_mp")} Megapixels`;
      if (window.templateFullWidth && window.templateFullHeight) {
        fullResolutionText = window.templateFullWidth + " × " + window.templateFullHeight;
      }
      if (this.hasFullResolutionTarget) $(this.fullResolutionTarget).text(fullResolutionText);
    }

    if (this.hasButtonTarget) {
      var note = $(this.buttonTarget).data("note");
      this.buttonVue = new Vue({
        el: this.buttonTarget,
        render: (h) =>
          h(FullImage, {
            props: {
              id: this.data.get("id"),
              url: this.data.get("url"),
              initialNote: note,
              basename: this.data.get("basename"),
              originalUrl: this.data.get("original-url"),
              foregroundType: this.data.get("foreground-type"),
              isTemplate: isTemplate,
              target: this.data.get("target"),
              downloadCallback: (url) => {
                // feed zipCanvas into full size graph
                window.template.runGraphWithImageSize(url, 0).then((base64) => {
                  createDownload(base64, window.template.foregroundFilename, true);
                  window.template.resetGraph();
                });
              },
            },
            ref: "fullimage",
          }),
      });
    }

    var tooltips = $(this.element).find(".hover-tooltip");
    if (tooltips.length > 0) {
      tippy(tooltips.get(), { allowHTML: true, interactive: true });
    }
  }

  // only used for template preview download
  download(e) {
    e.preventDefault();
    window.template.hiResChange(window.template.designTemplate.consts.OUTPUT_SIZE_PREVIEW_DOWNLOAD).then((base64) => {
      // download preview
      createDownload(base64, window.template.foregroundFilename, true);
      window.template.resetGraph();
    });
  }

  disconnect() {
    if (this.buttonVue) {
      this.buttonVue.$destroy();
    }
  }
}
