import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "answer", "iconDefault", "iconActive" ]

  connect() {
    this.visible = false;
  }

  toggle(e) {
    e.preventDefault()
    if(this.visible) {
      $(this.answerTarget).slideUp();
      $(this.iconDefaultTarget).show();
      $(this.iconActiveTarget).hide();
    }
    else {
      $(this.answerTarget).slideDown();
      $(this.iconActiveTarget).show();
      $(this.iconDefaultTarget).hide();
    }
    this.visible = !this.visible;
  }
}
