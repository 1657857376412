import $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

import { hcaptcha_invisible } from '../src/hcaptcha';

export default class extends Controller {
  static targets = [
    "section", "formfields", "type", "submitbtn", "feedback", "helpful", "success"
  ]

  connect() {}

  upvote(e) {
    this.typeTarget.value = "upvote"
    $(this.helpfulTarget).slideUp()
    this.submit(e)
  }

  downvote(e) {
    this.typeTarget.value = "downvote"
    $(this.helpfulTarget).slideUp()
    $(this.formfieldsTarget).slideDown()
  }

  submit(e) {
    $(this.submitbtnTarget).attr("disabled", true);
    e.preventDefault();
    hcaptcha_invisible({
      sitekey: this.data.get("hcaptcha-sitekey"),
    }).then((captcha) => {
      this.submitWithCaptcha(captcha);
    }).catch((error) => {
      $(this.submitbtnTarget).attr("disabled", false);
      if(error.event != "close") alert(error.message);
    })
  }

  submitWithCaptcha(token) {
    $.ajax({
      type: "POST",
      url: "/api/api_docs_feedback",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      data: {
        "hcaptcha_response": token,
        section: this.sectionTarget.value,
        type: this.typeTarget.value,
        feedback: this.feedbackTarget.value,
      },
      success: (data) => {
        if(data.status == "ok") {
          $(this.formfieldsTarget).slideUp();
          $(this.successTarget).slideDown();
        }
        else {
          alert(data.message);
        }
        $(this.submitbtnTarget).attr("disabled", false);
      },
      error: (e) => {
        $(this.submitbtnTarget).attr("disabled", false);
        if(e.status == 422 && e.responseJSON && e.responseJSON.message) {
          alert(e.responseJSON.message);
        }
        else {
          alert("Error: " + e.status + ". Please try again or contact team@remove.bg if the problem persists.");
        }
        $(this.submitbtnTarget).attr("disabled", false);
      }
    });
  }

}
