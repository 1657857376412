import $ from "jquery";
import Rails from "@rails/ujs";

import { Controller } from "stimulus";

import "daterangepicker/daterangepicker.css";

export default class extends Controller {
  static targets = ["canvas", "groupBy", "dateRange", "apiKey"];

  connect() {
    var datevalue = $(this.dateRangeTarget).val().split(" – ");

    import("moment").then(({ default: moment }) => {
      window.moment = moment;
      import("daterangepicker/daterangepicker.js").then(({ default: DateRangePicker }) => {
        import("chart.js/dist/Chart.bundle.min").then(({ default: Chart }) => {
          this.from = moment(datevalue[0]).format();
          this.to = moment(datevalue[1]).format();

          new DateRangePicker(
            this.dateRangeTarget,
            {
              locale: {
                format: "YYYY-MM-DD",
                separator: " – ",
                applyLabel: typeof I18n !== "undefined" ? I18n.t("editor.apply") : "Apply",
                cancelLabel: typeof I18n !== "undefined" ? I18n.t("editor.cancel") : "Cancel",
              },
            },
            (start, end, label) => {
              this.from = start.format();
              this.to = end.format();
              this.updateData();
            }
          );

          var ctx = this.canvasTarget.getContext("2d");
          this.chart = new Chart(ctx, {
            type: "line",
            data: {},
            options: {},
          });

          this.updateData();
        });
      });
    });
  }

  changeGroup(e) {
    this.updateData();
  }

  changeApiKey(e) {
    this.updateData();
  }

  updateData() {
    let data = {
      group: this.groupByTarget.value,
      from: this.from,
      to: this.to,
    };
    if (this.apiKeyTarget.value !== "all") {
      data.api_key_id = this.apiKeyTarget.value;
    }

    $.ajax({
      url: $(this.element).data("url"),
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      type: "GET",
      dataType: "json",
      data: data,
      success: (data) => {
        this.dataLoaded(data);
      },
      error: (e) => {
        alert("Failed to load chart data.");
      },
    });
  }

  dataLoaded(data) {
    this.chart.data = data.data;
    this.chart.options = data.options;
    this.chart.update();
  }
}
