import $ from "jquery";
import { Controller } from "stimulus"

function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

export default class extends Controller {
  static targets = [ ]

  connect() {
    this.date = parseInt(this.data.get("date")) * 1000;
    this.update();
    setInterval(() => {
      this.update();
    }, 1000);
  }

  update(e) {
    var now = Date.now();

    var text = '';
    if(now < this.date) {
      var diff = new Date(this.date - now);

      text = [
        pad(diff.getUTCHours(), 2),
        pad(diff.getUTCMinutes(), 2),
        pad(diff.getUTCSeconds(), 2),
      ].join(':');
    }
    else {
      text = this.data.get("over");
    }

    $(this.element).text(text);
  }
}
