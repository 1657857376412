import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.seconds = this.data.get("delay");
    this.name = this.data.get("name");
    this.url = this.data.get("url");
    this.countdown();
    this.interval = setInterval(() => { this.countdown(); }, 1000);
  }

  countdown() {
    this.seconds -= 1;
    if(this.seconds > 0) {
      $(this.element).text(`Downloading in ${this.seconds} second${this.seconds != 1 ? 's' : ''}…`);
    }
    else{
      clearInterval(this.interval);
      $(this.element).text(`Downloading…`);
      setTimeout(() => {
        var link = $("<a download>Start again</a>").attr("href", this.url);
        var p = $("<p style='font-size: 85%'></p>");
        link.appendTo(p);
        $(this.element).html(`<p>Download started.</p>`)
        p.appendTo(this.element);
        p.hide().slideDown();
      }, 1000);
      window.track("Download", "download", this.name);
      location.href = this.url;
    }
  }
}
