import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "userDropdownParent" ]

  connect() {
    var backdrop = $(".navbar-backdrop");

    $(this.userDropdownParentTarget).on("show.bs.dropdown", (e) => {
      this.loadBalanceInfo();
    })

    $(this.userDropdownParentTarget).on("shown.bs.dropdown", (e) => {
      backdrop.show();
    })

    $(this.userDropdownParentTarget).on("hidden.bs.dropdown", (e) => {
      this.hideBalanceInfo();
      backdrop.hide();
    })
  }

  loadBalanceInfo() {
    const locale = $("html").attr("lang");
    let url = "/balance_info";
    if(locale != "en") {
      url = `/${locale}/balance_info`;
    }

    $.ajax({
      type: "GET",
      url: url
    }).then((data) => {
      this.showBalanaceInfo(data)
    }).catch(() => {
      this.hideBalanceInfo()
    });
  }

  showBalanaceInfo(data) {
    $("#balance-info .content").html(data).show();
  }

  hideBalanceInfo() {
    $("#balance-info .content").html("").hide();
  }
}
