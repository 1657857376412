import Cookie from "../src/cookie.js";

export default {
  get: () => {
    return Cookie.get("contribute");
  },

  set: (value) => {
    Cookie.set("contribute", "functional", value, { expires: 365 });
  },
};
