import $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "description", "confirmBtn", "cancelBtn" ]

  confirm(e) {
    e.preventDefault()
    this._disable();
    const url = $(this.confirmBtnTarget).attr("data-url");
    $.ajax({
      type: "PUT",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      url: url,
      dataType: "json",
      success: (data) => {
        $(this.descriptionTarget).html(`
          <div style="padding: 50px; text-align: center;">
            <div><img src="/images/loader.gif" alt="Loading..." /></div>
            <div style="font-size: 20px;">
              Updating subscription …
            </div>
          </div>
        `)
        setTimeout(() => {
          location.href = "/dashboard"
        }, 3000)
      },
      error: (data) => {
        this._enable();
        alert("Failed to change subscription. Please try again or contact support if the problem persists.");
      }
    })
  }
  cancel(e) {
    e.preventDefault()
    this._close()
  }

  _disable() {
    $(this.confirmBtnTarget).addClass("disabled")
    $(this.cancelBtnTarget).addClass("disabled")
  }

  _enable() {
    $(this.confirmBtnTarget).removeClass("disabled")
    $(this.cancelBtnTarget).removeClass("disabled")
  }

  _close() {
    $.magnificPopup.close();
  }
}
