import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "url", "email", "copyStatus" ]

  sendEmail() {
    var msg = `Hi,

Check this out: remove.bg removes the background of any photo completely automatically, in less than 5 seconds!

If you sign up through this link both you and me get a bonus of 1 credit for a free full-resolution image:

${$(this.urlTarget).val()}

`

    var email = $(this.emailTarget).val();
    var mailto = `mailto:${encodeURIComponent(email)}?subject=${encodeURIComponent("Invite to remove.bg")}&body=${encodeURIComponent(msg)}`

    var link = $("<a />").attr("href", mailto).appendTo($("body"))
    link[0].click()
    link.remove()

    $(this.emailTarget).val('')

    window.track('ReferralProgram', `invite_by_email`, `Invite by Email`);
  }
  shareOnFacebook() {
    window.track('ReferralProgram', `share_on_facebook`, `Share on Facebook`);
  }
  shareOnTwitter() {
    window.track('ReferralProgram', `share_on_twitter`, `Share on Twitter`);
  }
  selectUrl() {
    $(this.urlTarget).select();
    window.track('ReferralProgram', `select_url`, `Select URL`);
  }
  copyUrl() {
    $(this.urlTarget).select();
    if(document.execCommand('copy') != false) {
      $(this.copyStatusTarget).show().fadeOut(3000)
    }
    window.track('ReferralProgram', `copy_url`, `Copy URL`);
  }
}
