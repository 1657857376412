import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "showMoreRow", "imagePrice",
    "actionBtn", "buyBtn", "contactBtn", "buyOrContactBtn", "currentBtn", "downgradeBtn", "upgradeBtn", "switchBtn", "contactSupportBtn",
    "purchaseSummary", "infoText", "contactInfoText", "buyInfoText", "buyOrContactInfoText"
  ]

  connect() {
    this.updateInterval();

    this.updateFromSelection();

    if(this.data.get("autobuy")) {
      this.buy();
    }
  }

  // MORE
  showMore(e) {
    if(e) e.preventDefault();
    this.getHidden().slice(0, 2).removeClass("additional-hidden");
    this.updateMoreButton();
  }

  showOnlyAroundSelection() {
    var selectable = $(this.element).find(".selectable");
    var selection = this.getSelection();

    var idx = selectable.index(selection);

    var showUntil = idx+3;
    if(showUntil < 5) showUntil = 5;

    selectable.slice(0, showUntil).removeClass("additional-hidden");
    selectable.slice(showUntil).addClass("additional-hidden");

    this.updateMoreButton();
  }

  updateMoreButton() {
    if(this.hasHidden()) {
      $(this.showMoreRowTarget).show();
    }
    else {
      $(this.showMoreRowTarget).hide();
    }
  }

  hasHidden() {
    return this.getHidden().length > 0;
  }

  getHidden() {
    return $(this.element).find(".additional-hidden");
  }

  // IMAGE PRICE

  updateImagePrice() {
    $(this.imagePriceTarget).text(this.getSelection().find(`.per-credit-value-${this.interval}`).text())
  }

  getSelection() {
    return $(this.element).find(".selectable.active");
  }

  deselect() {
    this.getSelection().removeClass("active");
  }

  select(e) {
    var selection = $(e.target).closest(".selectable");
    this.deselect();
    selection.addClass("active");

    this.updateFromSelection();
  }

  updateFromSelection() {
    this.updateImagePrice();
    this.showOnlyAroundSelection();
    this.updateButtons();
    this.updateInfoTexts();
    this.updateSummary();
  }

  updateButtons() {
    var selection = this.getSelection();
    var btn = selection.data(`${this.interval}-button`);
    $(this.actionBtnTargets).hide();
    $(this[`${btn}BtnTargets`]).show();
  }

  updateInfoTexts() {
    var selection = this.getSelection();
    var btn = selection.data(`${this.interval}-button`);
    $(this.infoTextTargets).hide();
    $(this[`${btn}InfoTextTargets`]).show();
  }

  updateSummary() {
    var selection = this.getSelection();
    $(this.purchaseSummaryTarget).text(selection.data(`${this.interval}-summary`));
  }

  buy() {
    var selection = this.getSelection();
    window.doPurchase(
      selection.data(`${this.interval}-paddle-id`),
      selection.data("paddle-type"),
      selection.data(`${this.interval}-paddle-coupon`),
    );
  }

  switch() {
    var selection = this.getSelection();
    var url = selection.data(`${this.interval}-plan-preview-url`);

    $.magnificPopup.open({
      midClick: true,
      items: {
        type: 'ajax',
        src: url
      }
    });
  }

  updateInterval() {
    this.interval = this.data.get("interval");
  }

  switchBillingInterval(e) {
    e.preventDefault();
    var btn = $(e.target);
    if(!btn.is("[data-value]")) btn = btn.closest("[data-value]");
    var value = btn.data("value");
    if(value == "toggle") value = this.interval == "yearly" ? "monthly" : "yearly";
    this.data.set("interval", value);
    this.updateInterval()
    this.updateFromSelection();
  }
}
