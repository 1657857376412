<template>
  <div class="full-image mt-2 d-flex flex-column align-items-center">
    <div v-if="status == 'ready' || status == 'png_ready'">
      <a href="#" class="btn btn-primary" v-on:click="download" v-if="target == 'download'">
        {{ I18n.t("image.download") }}
      </a>
      <div v-if="target == 'download_or_edit'" class="btn-group">
        <button type="button" class="btn btn-primary" @click="download">
          {{ I18n.t("image.download") }}
        </button>
        <button
          type="button"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="background: #0587f6; padding: 0 10px"
        >
          <span class="sr-only">Toggle dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right py-1">
          <a class="dropdown-item" href="#" @click.prevent="edit">{{ I18n.t("image.edit") }}</a>
        </div>
      </div>
      <div v-if="target == 'edit_or_download'" class="btn-group">
        <button type="button" class="btn btn-primary" @click="edit">
          {{ I18n.t("image.edit") }}
        </button>
        <button
          type="button"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="background: #0587f6; padding: 0 10px"
        >
          <span class="sr-only">Toggle dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right py-1">
          <a class="dropdown-item" href="#" @click.prevent="download">{{ I18n.t("image.download") }}</a>
        </div>
      </div>
    </div>
    <div v-if="status == 'processing' || status == 'zip_ready'">
      <div class="small">
        {{ statusMessage }}
      </div>
      <div class="progress" style="margin-bottom: 0">
        <div class="progress-bar" role="progressbar" v-bind:style="{ width: `${progressPercent}%` }"></div>
      </div>
    </div>
    <div v-if="status == 'error'" class="alert alert-danger">
      {{ statusMessage }}
    </div>
    <div v-if="note" class="text-muted mt-1" style="font-size: 80%; line-height: 1.4">
      {{ note }}
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Rails from "@rails/ujs";
import { ZipCanvas } from "../src/remove-bg-tools";
// import EditorUI from "../src/editor/editor_ui";
import blobToBase64 from "../src/blob_to_base64";
import { rbgImageDownloadV100 } from "kaleido-event-tracker";

export default {
  props: {
    id: String,
    url: String,
    initialNote: String,
    basename: String,
    originalUrl: String,
    foregroundType: String,
    isTemplate: Boolean,
    downloadCallback: Function,
    target: String,
  },
  data() {
    return {
      status: "ready",
      statusMessage: "",
      progressPercent: 0,
      zipUrl: "",
      requestedAction: null,
      zipCanvas: null,
      note: this.initialNote,
    };
  },
  methods: {
    download(e) {
      e.preventDefault();
      window.track("Images", "download_result_hd", "Download image result (HD)");
      rbgImageDownloadV100({ image_id: this.id, resolution: "high-definition", download_location: "legacy_main_flow" });
      if (window.hj) window.hj("trigger", "download_result_hd");

      if (window.hj) {
        if (this.foregroundType == "car") {
          window.hj("trigger", "download_car_result_hd");
        } else {
          window.hj("trigger", "download_result_hd");
        }
      }

      if (this.status == "png_ready") {
        this.downloadNow();
      }

      if (this.status == "ready") {
        this.requestedAction = "download";
        this.startProcessing();
      }
    },
    edit(e) {
      e.preventDefault();
      if (this.status == "png_ready") {
        this.editNow();
      }

      if (this.status == "ready") {
        this.requestedAction = "edit";
        this.startProcessing();
      }
    },
    startProcessing() {
      this.status = "processing";
      this.statusMessage = I18n.t("upload_page.processing");
      this.progressPercent = 10;
      this.fetchResult();
    },
    fetchResult() {
      $.ajax({
        type: "POST",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
        },
        url: `${this.url}/full_image.json`,
        dataType: "json",
        success: (data) => {
          this.statusUpdated(data);
        },
        error: (e) => {
          this.failed(I18n.t("upload_page.download_request_failed"));
        },
      });
    },
    statusUpdated(data) {
      switch (data.status) {
        case "error":
          this.failed(I18n.t("upload_page.processing_failed"));
          return;
        case "processing":
          if (data.percent_estimate) {
            this.progressPercent = 10 + data.percent_estimate * 0.5;
          }
          setTimeout(() => {
            this.fetchResult();
          }, data.fetch_in);
          return;
        case "zip_ready":
          this.progressPercent = 60;
          this.status = "zip_ready";
          if (data.note) this.note = data.note;
          this.fetchDownloadUrl();
          return;
      }
    },
    fetchDownloadUrl() {
      this.statusMessage = I18n.t("upload_page.downloading");
      this.progressPercent = 65;
      $.ajax({
        type: "GET",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
        },
        url: `${this.url}/full_image/download.json`,
        dataType: "json",
        success: (data) => {
          if (data && data.data && data.data.url) {
            this.zipUrl = data.data.url;
            this.convertZipToPng();
          } else {
            this.failed(I18n.t("upload_page.failed_to_fech_download_url"));
          }
        },
        error: (e) => {
          this.failed(I18n.t("upload_page.failed_to_fech_download_url"));
        },
      });
    },
    convertZipToPng() {
      this.progressPercent = 70;
      this.statusMessage = I18n.t("upload_page.saving");
      ZipCanvas.fromUrl(this.zipUrl, { mimeType: "image/png" }, (progress) => {
        if (progress.stage == "download") {
          this.statusMessage = I18n.t("upload_page.downloading");
        } else {
          this.statusMessage = I18n.t("upload_page.downloading");
        }
        this.progressPercent = 70 + progress.percent * 0.3;
      })
        .then((zipCanvas) => {
          // We only care about the first canvas containing the foreground in the old flow
          this.zipCanvas = zipCanvas[0];
          this.status = "png_ready";
          this.handleRequestedAction();
        })
        .catch((err) => {
          if (err.name == "InvalidStateError") {
            // Regression in Safari 15 https://bugs.webkit.org/show_bug.cgi?id=230855
            this.failed(I18n.t("upload_page.download_failed"));
          } else {
            this.failed(I18n.t("upload_page.failed_to_fech_download_url"));
          }
        });
    },
    handleRequestedAction() {
      if (this.requestedAction == "download") return this.downloadNow();
      if (this.requestedAction == "edit") return this.editNow();
    },
    downloadNow() {
      if (this.isTemplate) {
        this.statusMessage = I18n.t("upload_page.downloading");

        var url = this.zipCanvas.createURL();
        this.downloadCallback(url);
      } else {
        var filename = this.basename + "-removebg.png";
        if (window.mobile_app) {
          var b64_regexp = /^data:image\/png;base64,/;
          if (this.zipCanvas.dataUrl) {
            window.mobile_app.downloadBase64(filename, this.zipCanvas.dataUrl.replace(b64_regexp, ""));
          } else if (this.zipCanvas.blob) {
            blobToBase64(this.zipCanvas.blob).then((base64) => {
              window.mobile_app.downloadBase64(filename, base64.replace(b64_regexp, ""));
            });
          }
        } else {
          this.zipCanvas.download(filename);
        }
      }
    },
    editNow() {
      var url = this.zipCanvas.createURL();

      // TODO: for clean up do at some point
      // this.zipCanvas.revokeURL(url);
    },
    failed(message) {
      this.status = "error";
      this.statusMessage = message;
    },
  },
};
</script>

<style scoped></style>
