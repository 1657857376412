export function createDownload(downloadBase64, original_filename, hires = false) {
  let a = document.createElement("a");
  var filename = original_filename.split(".").slice(0, -1).join(".") + "-removebg";
  if (!hires) filename += "-preview";
  let outputNode = window.template.designTemplate.getOutputNode();
  switch (outputNode.type) {
    case Jimp.MIME_JPEG:
      a.download = filename + ".jpg";
      break;
    case Jimp.MIME_PNG:
      a.download = filename + ".png";
      break;
    case Jimp.MIME_BMP:
      a.download = filename + ".bmp";
      break;
    case Jimp.MIME_TIFF:
      a.download = filename + ".tif";
      break;
  }
  a.href = URL.createObjectURL(dataURLtoBlob(downloadBase64));
  a.click();
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
