import $ from "jquery";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import Rails from '@rails/ujs';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["termsCheckbox", "submitBtn"];

  connect() {
    import(/* webpackChunkName: "uppy" */ "@uppy/core").then(({ default: Uppy }) => {
      import(/* webpackChunkName: "uppy" */ "@uppy/dashboard").then(({ default: Dashboard }) => {
        import(/* webpackChunkName: "uppy" */ "@uppy/xhr-upload").then(({ default: XHRUpload }) => {
          this.uppy = Uppy({
            debug: false,
            autoProceed: false,
            restrictions: {
              maxFileSize: 12000000,
              maxNumberOfFiles: 500,
              minNumberOfFiles: 1,
              allowedFileTypes: ["image/jpeg", "image/png", ".jpg", ".jpeg", ".png"],
            },
          })
            .use(Dashboard, {
              inline: true,
              target: ".training-image-form",
              replaceTargetContent: true,
              showProgressDetails: true,
              note: "JPG/PNG images only, 1–500 files, up to 12 MB",
              height: 370,
              proudlyDisplayPoweredByUppy: false,
              showLinkToFileUploadResult: false,
              metaFields: [{ id: "name", name: "Name", placeholder: "file name" }],
            browserBackButtonClose: true,
            hideUploadButton: true,
          })
          .use(XHRUpload, {
            endpoint: '/training_images',
            fieldName: 'training_image[image]',
            limit: 20,
            headers: {
              'X-CSRF-Token': Rails.csrfToken()
            }
          })

          this.uppy.on("complete", (result) => {
            $(this.termsCheckboxTarget).attr("disabled", false);
            $(this.submitBtnTarget).attr("disabled", false);

            var successmsg = "";
            if (result.successful.length == 1) {
              successmsg = `${result.successful.length} image has been successfully uploaded.`;
            } else {
              successmsg = `${result.successful.length} images have been successfully uploaded.`;
            }
            var errormsg = "";
            if (result.failed.length > 0) {
              errormsg = `(${result.failed.length} files failed to upload)`;
            }
            $.magnificPopup.open({
              items: {
                src: `<div class="white-popup">
                  <h2>Thanks for your support!</h2>
                  <p>
                    <strong>
                      ${successmsg}
                      ${errormsg}
                    </strong>
                  </p>
                  <p>
                    Your training images enable us to improve remove.bg. Thank you!
                  </p>
                  <p>
                    If you want to be notified of future releases and updates, subscribe to our <a href="/#get-updates" target="_blank">mailing list</a> or follow us on <a href="https://facebook.com/removebg" target="_blank">Facebook</a> or <a href="https://twitter.com/remove_bg" target="_blank">Twitter</a>.
                  </p>
                </div>`,
                type: "inline",
              },
              callbacks: {
                close: function () {
                  location.reload();
                },
              },
            });
          });
        });
      });
    });
  }

  toggleSubmitButton() {
    $(this.submitBtnTarget).attr("disabled", !$(this.termsCheckboxTarget).is(":checked"));
  }

  submit() {
    if (this.uppy.getFiles().length == 0) return;
    $(this.termsCheckboxTarget).attr("disabled", true);
    $(this.submitBtnTarget).attr("disabled", true);
    this.uppy.upload();
  }
}
