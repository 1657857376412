import $ from "jquery";
import { Controller } from "stimulus"

import improvedecision from '../src/improvedecision';

export default class extends Controller {
  static targets = [
    "initial", "accepted", "rejected",
    "terms", "remember"
  ]

  connect() {
    $(this.initialTarget).show();
    $(this.acceptedTarget).hide();
    $(this.rejectedTarget).hide();
    $(this.termsTarget).removeClass("text-danger");
    $(".mfp-close").show();
  }

  accept() {
    if($(this.termsTarget).find("input").is(":checked")) {
      $(this.termsTarget).removeClass("text-danger");
      $(".mfp-close").hide();
      $(this.initialTarget).hide();
      $(this.acceptedTarget).show();

      this.rememberDecision("accept");
      window.improveDialogCallback('accept');
    }
    else {
      $(this.termsTarget).addClass("text-danger");
    }
  }

  reject() {
    $(".mfp-close").hide();
    $(this.initialTarget).hide();
    $(this.rejectedTarget).show();

    this.rememberDecision("reject");
    window.improveDialogCallback('reject');
  }

  rememberDecision(value) {
    if($(this.rememberTarget).find("input").is(":checked")) {
      improvedecision.set(value);
    }
  }

}
