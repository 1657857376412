import $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

import { hcaptcha_invisible } from '../src/hcaptcha';

export default class extends Controller {
  static targets = [ "answer", "iconDefault", "iconActive",
    "slug", "formfields", "type", "submitbtn", "feedback", "helpful", "success" ]

  connect() {
    this.visible = false;
  }

  toggle(e) {
    e.preventDefault()
    if(this.visible) {
      $(this.answerTarget).slideUp();
      $(this.iconDefaultTarget).show();
      $(this.iconActiveTarget).hide();
    }
    else {
      $(this.answerTarget).slideDown();
      $(this.iconActiveTarget).show();
      $(this.iconDefaultTarget).hide();
    }
    this.visible = !this.visible;
  }

  connect() {}

  upvote(e) {
    this.typeTarget.value = "upvote"
    $(this.helpfulTarget).slideUp()
    this.submit(e)
  }

  downvote(e) {
    this.typeTarget.value = "downvote"
    $(this.helpfulTarget).slideUp()
    $(this.formfieldsTarget).slideDown()
  }

  submit(e) {
    $(this.submitbtnTarget).attr("disabled", true);
    e.preventDefault();
    hcaptcha_invisible({
      sitekey: this.data.get("hcaptcha-sitekey"),
    }).then((captcha) => {
      this.submitWithCaptcha(captcha);
    }).catch((error) => {
      $(this.submitbtnTarget).attr("disabled", false);
      if(error.event != "close") alert(error.message);
    })
  }

  submitWithCaptcha(token) {
    $.ajax({
      type: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      url: "/api/helpcenter_feedback",
      data: {
        "hcaptcha_response": token,
        slug: this.slugTarget.value,
        type: this.typeTarget.value,
        feedback: this.feedbackTarget.value,
      },
      success: (data) => {
        if(data.status == "ok") {
          $(this.formfieldsTarget).slideUp();
          $(this.successTarget).slideDown();
        }
        else {
          alert(data.message);
        }
        $(this.submitbtnTarget).attr("disabled", false);
      },
      error: (e) => {
        $(this.submitbtnTarget).attr("disabled", false);
        if(e.status == 422 && e.responseJSON && e.responseJSON.message) {
          alert(e.responseJSON.message);
        }
        else {
          alert("Error: " + e.status + ". Please try again or contact team@remove.bg if the problem persists.");
        }
        $(this.submitbtnTarget).attr("disabled", false);
      }
    });
  }
}
