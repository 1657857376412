import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdownParent" ]

  connect() {
    var backdrop = $(".navbar-backdrop");

    $(this.dropdownParentTargets).on("shown.bs.dropdown", (e) => {
      backdrop.show();
    })

    $(this.dropdownParentTargets).on("hidden.bs.dropdown", (e) => {
      backdrop.hide();
    })
  }
}
