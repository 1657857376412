import $ from "jquery";
import { Controller } from "stimulus"

import Cookie from '../src/cookie.js'
export default class extends Controller {
  close(e) {
    e.preventDefault();
    Cookie.set("hiringbanner", "functional", "closed", { expires: 365 })
    $(this.element).fadeOut(300)
  }
}
