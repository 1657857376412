import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "language", "code" ]

  connect() {
    this._updateSelection();
    this._showCode();
  }

  selectSource(e) {
    $(this.sourceTarget).find(".active").removeClass("active");
    $(e.target).addClass("active");
    this._updateSelection();
    this._showCode();
  }

  selectLanguage(e) {
    $(this.languageTarget).find(".active").removeClass("active");
    $(e.target).addClass("active");
    this._updateSelection();
    this._showCode();
  }

  _updateSelection() {
    this.source = $(this.sourceTarget).find(".active").data("value");
    this.language = $(this.languageTarget).find(".active").data("value");
  }

  _showCode() {
    $(this.codeTargets).addClass("d-none")
    $(this.codeTargets).filter(`[data-source='${this.source}'][data-lang='${this.language}']`).removeClass("d-none")
  }
}
