import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "original", "grid", "transparent", "transparentInner" ]

  connect() {
    this.step = 0;
    this.interval = setInterval(this.cycle.bind(this), 1500);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  cycle() {
    switch(this.step) {
      case 0:
        $(this.transparentTarget).removeClass("animation-demo-image-hidden");
        break;
      case 1:
        $(this.originalTarget).addClass("animation-demo-image-hidden");
        break;
      case 2:
        $(this.gridTarget).addClass("animation-demo-image-hidden");
        break;
      case 3:
        break;
      case 4:
      case 5:
      case 6:
      case 7:
        // just wait
        break;
      case 8:
        $(this.transparentTarget).addClass("animation-demo-image-hidden");
        $(this.originalTarget).removeClass("animation-demo-image-hidden");
        break;
      case 9:
        $(this.gridTarget).removeClass("animation-demo-image-hidden")
        break;
    }
    this.step = (this.step + 1) % 10;
  }
}
