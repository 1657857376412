import $ from "jquery";
import { Controller } from "stimulus"

import Rails from "@rails/ujs";
import { hcaptcha_invisible } from '../src/hcaptcha';

export default class extends Controller {
  static targets = [
    "email", "formfields", "success", "successText", "submitbtn"
  ]

  submit(e) {
    $(this.submitbtnTarget).attr("disabled", true);
    e.preventDefault();
    hcaptcha_invisible({
      sitekey: this.data.get("hcaptcha-sitekey"),
    }).then((captcha) => {
      this.submitWithCaptcha(captcha);
    }).catch((error) => {
      $(this.submitbtnTarget).attr("disabled", false);
      if(error.event != "close") alert(error.message);
    })
  }

  submitWithCaptcha(token) {
    $.ajax({
      type: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      url: "/newsletter_optins",
      data: {
        "hcaptcha_response": token,
        email: $(this.emailTarget).val()
      },
      success: (data) => {
        if(data.status == "ok") {
          $(this.formfieldsTarget).slideUp();
          $(this.successTextTarget).text(data.message);
          $(this.successTarget).slideDown();
        }
        else {
          alert(data.message);
        }
        $(this.submitbtnTarget).attr("disabled", false);
      },
      error: (e) => {
        $(this.submitbtnTarget).attr("disabled", false);
        if(e.status == 422 && e.responseJSON && e.responseJSON.message) {
          alert(e.responseJSON.message);
        }
        else {
          alert("Error: " + e.status + ". Please try again or contact team@remove.bg if the problem persists.");
        }
        $(this.submitbtnTarget).attr("disabled", false);
      }
    });
  }

}
